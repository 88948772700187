import React, { useEffect, useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import { useCurrentUser } from '../../currentUserContext'
import Clock from 'react-clock'
import * as moment from 'moment'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import Avatar from '@mui/material/Avatar'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { Context } from '../../breadcrumsContext'
import { useDocumentTitle } from '../../documentTitle'

import '../../UI/toastify/ReactToastify.min.css'
import Spinner from '../../UI/Spinner'

import '../../../asset/style.css'

import 'react-clock/dist/Clock.css'

const ALL_USER_TASKS = loader('./graphql/allTasksByUser.graphql')
const ALL_TASKS = loader('./graphql/allTasks.graphql')

export default function Repports() {
	const currentUser = useCurrentUser()
	const { updateName } = useContext(Context)
	useDocumentTitle('Dashboard')
	const [value, setValue] = useState(new Date())

	const {
		data: { allTasksByUser = null } = {},
		loading: allTasksByUserLoading,
	} = useQuery(ALL_USER_TASKS, {
		variables: { assignedTo: currentUser && currentUser.id },
	})

	const { data: { allTasks = null } = {}, loading: allTasksLoading } =
		useQuery(ALL_TASKS)

	function getDayName(date = new Date(), locale = 'fr-FR') {
		return date.toLocaleDateString(locale, { weekday: 'long' })
	}

	useEffect(() => {
		const interval = setInterval(() => setValue(new Date()), 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		updateName(
			JSON.stringify([{ level: '1', title: 'Dashboard', url: '/dashboard' }])
		)
	}, [updateName])

	if (!currentUser) return <Redirect to="/" />

	if (allTasksByUserLoading || allTasksLoading) return <Spinner />

	return (
		<>
			<Box className="repportContainer">
				<Paper className="repportWidgetList">
					<h2>Mes tâches</h2>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell component="th" style={{ fontWeight: 'bold' }}>
									Tâche
								</TableCell>
								<TableCell component="th" style={{ fontWeight: 'bold' }}>
									Client
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{allTasksByUser.map(
								(row) =>
									row?.status !== 'completed' &&
									row?.status !== 'invoiced' && (
										<TableRow key={row.id}>
											<TableCell>{row?.number}</TableCell>
											<TableCell>{row?.client.company}</TableCell>
											<TableCell>
												<Tooltip title="Ouvrir">
													<IconButton
														component={Link}
														to={`/modifier-tache/${row.id}`}
														edge="end"
													>
														<OpenInNewIcon />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									)
							)}
						</TableBody>
					</Table>
				</Paper>
				<Paper className="repportWidget">
					<div className="avatarContainer">
						<Avatar
							sx={{
								bgcolor: currentUser.color,
								width: 70,
								height: 70,
								fontSize: 26,
							}}
						>
							{currentUser.userName !== ''
								? currentUser.userName?.substring(0, 2).toUpperCase()
								: 'AA'}
						</Avatar>
						<div className="avatarInfo">
							<h1>{currentUser.userName}</h1>
							<h2>{currentUser.name}</h2>
						</div>
					</div>
					<Clock value={value} />

					<p>
						<span style={{ textTransform: 'capitalize' }}>{getDayName()} </span>
						{moment(new Date(), 'x').format('DD/MM/YYYY').toString()}
					</p>
				</Paper>
				<Paper className="repportWidgetList">
					<h2>Tâches non-assigné</h2>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell component="th" style={{ fontWeight: 'bold' }}>
									Tâche
								</TableCell>
								<TableCell component="th" style={{ fontWeight: 'bold' }}>
									Client
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{allTasks
								.filter(
									({ assignedTo }) => assignedTo === null || assignedTo === ''
								)
								.map((row) => (
									<TableRow key={row.id}>
										<TableCell>{row.number}</TableCell>
										<TableCell>{row.client.company}</TableCell>
										<TableCell>
											<Tooltip title="Ouvrir">
												<IconButton
													component={Link}
													to={`/modifier-tache/${row.id}`}
													edge="end"
												>
													<OpenInNewIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Paper>
			</Box>
		</>
	)
}
