import React, { useEffect, useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'

import { Button } from '@material-ui/core'

import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@material-ui/core/InputAdornment'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Tooltip from '@mui/material/Tooltip'

import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CLIENT = loader('./graphql/client.graphql')
const ALL_SERVICES = loader('./graphql/allServices.graphql')
const CREATE_CLIENT_SERVICE_PRICE = loader(
	'./graphql/createClientServicePrice.graphql'
)
const REMOVE_CLIENT_SERVICE_PRICE = loader(
	'./graphql/removeClientServicePrice.graphql'
)

const EditClientRate = () => {
	const { updateName } = useContext(Context)
	const params = useParams() //get the client ID
	const [serviceId, setServiceId] = useState()

	const {
		data: { client = {} } = {},
		loading: clientLoading,
		refetch,
	} = useQuery(CLIENT, {
		variables: { id: params.id },
	})

	const { data: { allServices = null } = {}, loading: serviceLoading } =
		useQuery(ALL_SERVICES)

	const [
		createClientServicePrice,
		{
			data: createClientServiceData,
			error: createClientServiceError,
			loading: createClientServiceLoading,
		},
	] = useMutation(CREATE_CLIENT_SERVICE_PRICE)

	const [
		removeClientServicePrice,
		{
			data: removeClientServiceData,
			error: removeClientServiceError,
			loading: removeClientServiceLoading,
		},
	] = useMutation(REMOVE_CLIENT_SERVICE_PRICE)

	useEffect(() => {
		refetch()
	}, [createClientServiceData, removeClientServiceData])

	const handleRemoveService = ({ serviceId }) => {
		removeClientServicePrice({
			variables: {
				input: {
					clientId: params.id,
					serviceId,
				},
			},
		})
	}

	const handleAddService = (e) => {
		e.preventDefault()
		const { price, remark } = e.target
		createClientServicePrice({
			variables: {
				input: {
					clientId: params.id,
					serviceId,
					price: Number(price.value),
					remark: remark.value,
				},
			},
		})
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier tarifs client',
					url: `/client-tarifs/${client && client?.id}`,
				},
				{
					level: '2',
					title: `${client && client?.company}`,
					url: `/client-tarifs/${client && client?.id}`,
				},
			])
		)
	}, [updateName, client])

	useDocumentTitle(
		client
			? 'Modifer les tarifs du client ' + client?.company
			: 'Modifer les tarifs client '
	)

	if (createClientServiceLoading || serviceLoading || clientLoading)
		return <Spinner />

	return (
		<>
			{client && (
				<Box className="mainZone">
					<Paper
						style={{
							width: '100%',
							height: '100px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							padding: ' 0 20px',
							marginBottom: '20px',
						}}
						component="form"
						noValidate
						autoComplete="off"
						onSubmit={handleAddService}
					>
						<FormGroup className="formGrp" row>
							{allServices && (
								<Autocomplete
									onChange={(i, value) => setServiceId(value?.id)}
									options={
										client?.servicesPrice?.length
											? allServices.filter(
													({ id }) =>
														!client.servicesPrice.find(
															({ service }) => service.id === id
														)
											  )
											: allServices
									}
									style={{ marginRight: '20px' }}
									getOptionLabel={(option) => option.name}
									renderOption={(props, option) => (
										<Box
											key={option.id}
											component="li"
											sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
											{...props}
										>
											&nbsp;&nbsp;{option.name}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Service"
											name="service"
											size="small"
											margin="dense"
										/>
									)}
								/>
							)}

							<TextField
								style={{ marginRight: '20px' }}
								label="Prix"
								size="small"
								type="number"
								margin="dense"
								inputProps={{ min: 0 }}
								name="price"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">€</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Remarque"
								size="small"
								margin="dense"
								style={{ marginRight: '20px', width: '500px' }}
								name="remark"
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={!serviceId}
							>
								Ajouter
							</Button>
						</FormGroup>
					</Paper>
					<Paper sx={{ width: '100%', mb: 2 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={{ fontWeight: 'bold' }}>Service</TableCell>
									<TableCell style={{ fontWeight: 'bold' }} align="right">
										Prix&nbsp;(€)
									</TableCell>
									<TableCell style={{ fontWeight: 'bold' }}>Remarque</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{client &&
									client?.servicesPrice?.map((elm) => {
										return (
											<TableRow>
												<TableCell component="th" scope="row">
													{elm?.service?.name}
												</TableCell>
												<TableCell align="right">{elm?.price}</TableCell>
												<TableCell>{elm?.remark}</TableCell>
												<TableCell align="right">
													<Tooltip title="Retiré">
														<IconButton
															edge="end"
															aria-label="delete"
															onClick={() =>
																handleRemoveService({
																	serviceId: elm?.service?.id,
																})
															}
														>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</TableCell>
											</TableRow>
										)
									})}
							</TableBody>
						</Table>
					</Paper>
					<FormGroup className="formGrp" row>
						<SystemInfo
							createdBy={client.createdBy}
							createdAt={client.createdAt}
							updatedBy={client.updatedBy}
							updatedAt={client.updatedAt}
						/>
					</FormGroup>
				</Box>
			)}
		</>
	)
}

export default EditClientRate
