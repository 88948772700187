import React, { createContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.scss'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { CurrentUserProvider } from './currentUserContext'
import Provider from './breadcrumsContext'

import Header from './UI/Header'
// Pages
import Home from './pages/Home'
import Login from './interaction/Login'

import CreateClient from './interaction/create/CreateClient'
import EditClient from './interaction/edit/EditClient'
import CreateService from './interaction/create/CreateService'
import EditService from './interaction/edit/EditService'
import CreateUser from './interaction/create/CreateUser'
import EditUser from './interaction/edit/EditUser'
import CreateTask from './interaction/create/CreateTask'
import EditTask from './interaction/edit/EditTask'
import ClientList from './pages/lists/ClientList'
import ServiceList from './pages/lists/ServiceList'
import TaskList from './pages/lists/TaskList'
import UserTaskList from './pages/lists/TaskByUser'
import UserList from './pages/lists/UserList'
import AccountingList from './pages/lists/AccountingList'
import Repports from './pages/Repports'
import ClientTasksList from './pages/lists/ClientTasksList'
import ClientWithActiveTaskList from './pages/lists/ClientWithActiveTaskList'
import CreateInvoice from './interaction/create/CreateInvoice'
import ClientRateList from './pages/lists/ClientRateList'
import ClientRates from './interaction/edit/EditClientRate'
import CallList from './pages/lists/CallList'
import CreateCall from './interaction/create/CreateCall'
import EditCall from './interaction/edit/EditCall'

import TagList from './pages/lists/TagList'
import CreateTag from './interaction/create/CreateTag'
import EditTag from './interaction/edit/EditTag'

import TemplateList from './pages/lists/TemplateList'
import CreateTemplate from './interaction/create/CreateTemplate'
import EditTemplate from './interaction/edit/EditTemplate'

import Dashboard from './pages/dashboard'
import Page404 from './pages/Page404'

const App = () => {
	const theme = createTheme({
		palette: {
			secondary: {
				main: '#FF9100',
			},
			primary: {
				main: '#0f2765',
			},
		},
	})
	return (
		<>
			<div className="App" id="root">
				<MuiThemeProvider theme={theme}>
					<CurrentUserProvider>
						<Provider>
							<Header />
							<Switch>
								<Route path="/home">
									<Home />
								</Route>
								<Route path="/dashboard">
									<Dashboard />
								</Route>
								{/* Task */}
								<Route path="/liste-taches">
									<TaskList />
								</Route>
								<Route path="/mes-taches">
									<UserTaskList />
								</Route>
								<Route path="/nouvelle-tache">
									<CreateTask />
								</Route>
								<Route path="/modifier-tache/:id">
									<EditTask />
								</Route>
								{/* Calls */}
								<Route path="/liste-calls">
									<CallList />
								</Route>
								<Route path="/nouveau-call">
									<CreateCall />
								</Route>
								<Route path="/modifier-call/:id">
									<EditCall />
								</Route>
								{/* Client */}
								<Route path="/nouveau-client">
									<CreateClient />
								</Route>
								<Route path="/modifier-client/:id">
									<EditClient />
								</Route>
								<Route path="/liste-clients">
									<ClientList />
								</Route>
								{/* Service */}
								<Route path="/nouveau-service">
									<CreateService />
								</Route>
								<Route path="/modifier-service/:id">
									<EditService />
								</Route>
								<Route path="/liste-services">
									<ServiceList />
								</Route>
								{/* Users */}
								<Route path="/nouveau-utilisateur">
									<CreateUser />
								</Route>
								<Route path="/modifier-utilisateur/:id">
									<EditUser />
								</Route>
								{/* Tags */}
								<Route path="/liste-tags">
									<TagList />
								</Route>
								<Route path="/nouveau-tag">
									<CreateTag />
								</Route>
								<Route path="/modifier-tag/:id">
									<EditTag />
								</Route>
								{/* Template */}
								<Route path="/liste-templates">
									<TemplateList />
								</Route>
								<Route path="/nouveau-template">
									<CreateTemplate />
								</Route>
								<Route path="/modifier-template/:id">
									<EditTemplate />
								</Route>
								{/* Users */}
								<Route path="/liste-utilisateurs">
									<UserList />
								</Route>
								{/* Client-Service-Price */}
								<Route path="/liste-clients-tarifs">
									<ClientRateList />
								</Route>
								<Route path="/client-tarifs/:id">
									<ClientRates />
								</Route>
								{/* Invoice */}
								<Route path="/factures">
									<AccountingList />
								</Route>
								<Route path="/nouvelle-facture/:id">
									<CreateInvoice />
								</Route>
								<Route path="/taches-client/:id">
									<ClientTasksList />
								</Route>
								<Route path="/liste-client-avec-active-taches">
									<ClientWithActiveTaskList />
								</Route>
								{/* Repport */}
								<Route path="/Rapports">
									<Repports />
								</Route>
								{/* Main UI pages */}
								<Route exact path="/">
									<Login />
								</Route>

								<Route path="*">
									<Page404 />
								</Route>
							</Switch>
						</Provider>
					</CurrentUserProvider>
				</MuiThemeProvider>
			</div>
		</>
	)
}

export default App
