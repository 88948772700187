import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import * as moment from 'moment'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CLIENT = loader('./graphql/client.graphql')

export default function ClientTaskList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des tâches par client')
	const params = useParams()
	const [selectionModel, setSelectionModel] = React.useState([])

	const {
		data: { client = {} } = {},
		loading: clientLoading,
		refetch,
	} = useQuery(CLIENT, {
		variables: { id: params.id },
	})

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					component={Link}
					to={`/nouvelle-facture/${params.id}`}
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<NoteAddIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Convertir
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 150, hide: true, flex: 1 },
		{
			field: 'service',
			headerName: 'Service',
			flex: 1,
			valueGetter: ({ value }) => value?.name,
		},
		{ field: 'quantity', headerName: 'Quantité', flex: 1 },
		{
			field: 'finalPrice',
			headerName: 'Prix (€)',
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return row.finalPrice + ' €'
			},
		},
		{
			field: 'total',
			headerName: 'Total (€)',
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return row.finalPrice * row.quantity + ' €'
			},
		},
		{
			field: 'remark',
			headerName: 'Remarque',
			flex: 1,
			valueGetter: (params) => params.row?.service?.description,
		},
		{
			field: 'task',
			headerName: 'Tache',
			flex: 1,
			valueGetter: (params) => params.row?.task?.number,
		},
		{
			field: 'createdAt',
			headerName: 'Date',
			flex: 1,
			// 	valueGetter: (params) =>
			// 		new Date(Number(params.row?.task.createdAt)).toISOString().slice(0, 10),
			// },
			valueGetter: (params) => {
				return moment(params.row?.task?.createdAt, 'x').format('DD/MM/YY HH:mm')
			},
		},
		{
			field: 'assignedTo',
			headerName: 'Utilisateur',
			flex: 1,
			valueGetter: (params) => params.row?.task?.assignedTo?.userName,
		},
	]

	useEffect(() => {
		localStorage.setItem('selectedServices', selectionModel)
	}, [selectionModel])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Liste des tâche par client',
					url: `/taches-client/${client && client?.id}`,
				},
				{
					level: '2',
					title: `${client && client?.company}`,
					url: `/modifier-client/${client && client?.id}`,
				},
			])
		)
	}, [updateName, client])

	useEffect(() => {
		refetch()
	}, [client])

	if (clientLoading) return <Spinner />

	return (
		<>
			{client && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={client.unpaidServices}
					checkboxSelection
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel)
					}}
					selectionModel={selectionModel}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}
		</>
	)
}
