import React, { useEffect, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'
import InputMask from 'react-input-mask'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import Spinner from '../../../UI/Spinner'

import '../../../../asset/style.css'

const CREATE_CLIENT = loader('./graphql/createClient.graphql')
// const CREATE_FOLDER = loader('./graphql/createFolder.graphql')

const CreateClient = () => {
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouveau client')

	const [CreateClient, { data, error, loading }] = useMutation(CREATE_CLIENT)
	// const [CreateFolder] = useMutation(CREATE_FOLDER) // Use folder creation mutation
	const [formIsValid, setFormIsValid] = useState(false)
	const [fieldErrors, setFieldErrors] = useState({
		email: false,
		phone: false,
		tva: false,
	})

	const validateInput = (fieldName, value) => {
		if (fieldName === 'email') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			return emailRegex.test(value)
		}
		if (fieldName === 'phone') {
			const phoneRegex = /^(?:\+\d{1,4})?\d{10}$/
			return phoneRegex.test(value)
		}
		if (fieldName === 'tva') {
			const tvaRegex = /^BE\d{10}$/
			return tvaRegex.test(value)
		}
		return true
	}

	const handleInputChange = (e) => {
		const fieldName = e.target.name
		const value = e.target.value
		const isValid = validateInput(fieldName, value)

		setFieldErrors({ ...fieldErrors, [fieldName]: !isValid })
		checkFormValidity()
	}

	const checkFormValidity = () => {
		const form = document.forms[0] // Assuming this is the first form in the component
		setFormIsValid(form.checkValidity())
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const form = e.target

		const clientInput = {
			company: form.company.value,
			phone: form.phone.value,
			email: form.email.value,
			language: form.language.value,
			tva: form.tva.value,
			adminstrator: form.adminstrator.value,
		}

		try {
			// Step 1: Create the client
			const { data: clientData } = await CreateClient({
				variables: { input: clientInput },
			})

			if (clientData?.createClient) {
				const clientId = clientData.createClient.id
				const folderName = clientInput.company.replace(/[^a-zA-Z0-9]/g, '_')

				// Step 2: Create the OneDrive folder
				await CreateFolder({
					variables: {
						input: {
							clientId,
							folderName,
						},
					},
				})

				console.log('Client and folder created successfully')
			}
		} catch (err) {
			console.error('Error creating client or folder:', err)
		}
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Nouveau client', url: '/nouveau-client' },
			])
		)
	}, [updateName])

	if (loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'clientCreateMsg',
			'✔️ Le client a été crée avec succès ! '
		)
		return <Redirect to="/liste-clients" />
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp-around" row>
					<TextField
						label="Societe"
						name="company"
						size="small"
						margin="normal"
						required
						onChange={checkFormValidity}
					/>
					{/* <TextField
						label="Forme Jurique"
						name="form"
						size="small"
						margin="normal"
					/> */}

					<FormControl
						className="formSelect"
						size="small"
						margin="normal"
						required
					>
						<InputLabel>Langue</InputLabel>
						<Select labelId="Language" name="language" defaultValue="fr_BE">
							<MenuItem key="fr" value="fr_BE">
								FR
							</MenuItem>
							<MenuItem key="nl" value="nl_BE">
								NL
							</MenuItem>
							<MenuItem key="en" value="en_US">
								EN
							</MenuItem>
						</Select>
					</FormControl>
				</FormGroup>
				<FormGroup className="formGrp-around" row>
					<TextField
						label="Administrateur"
						name="adminstrator"
						size="small"
						margin="normal"
						required
						onChange={checkFormValidity}
					/>
					<InputMask
						mask="BE9999999999"
						maskChar=""
						onChange={(e) => {
							e.preventDefault()
							checkFormValidity()
							handleInputChange(e)
						}}
					>
						{() => (
							<TextField
								label="Numéro TVA"
								name="tva"
								size="small"
								margin="normal"
								required
								error={fieldErrors.tva}
								helperText={fieldErrors.tva ? 'invalide tva' : ''}
							/>
						)}
					</InputMask>
					{/* <TextField
						label="Numéro IBAN"
						name="iban"
						size="small"
						margin="normal"
					/> */}
				</FormGroup>
				<FormGroup className="formGrp-around" row>
					<TextField
						label="Téléphone"
						name="phone"
						type="tel"
						size="small"
						margin="normal"
						required
						error={fieldErrors.phone}
						helperText={fieldErrors.phone ? 'invalide numéro' : ''}
						onChange={(e) => {
							e.preventDefault()
							checkFormValidity()
							handleInputChange(e)
						}}
					/>

					<TextField
						label="E-mail"
						name="email"
						type="email"
						size="small"
						margin="normal"
						required
						error={fieldErrors.email}
						helperText={fieldErrors.email ? 'invalide email' : ''}
						onChange={(e) => {
							e.preventDefault()
							checkFormValidity()
							handleInputChange(e)
						}}
					/>
				</FormGroup>
				<FormGroup className="formGrp-around " row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
						disabled={!formIsValid}
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateClient
