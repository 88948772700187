import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@mui/material/TextareaAutosize' // Import Material-UI TextareaAutosize
import FormGroup from '@material-ui/core/FormGroup'
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Spinner from '../../../UI/Spinner'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const CREATE_TEMPLATE = loader('./graphql/createTemplate.graphql')

const CreateTemplate = () => {
	const [emailBody, setEmailBody] = useState('')
	const { updateName } = useContext(Context)
	useDocumentTitle('Nouveau template')

	const [CreateTemplate, { data, error, loading }] =
		useMutation(CREATE_TEMPLATE)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, body } = e.target
		return CreateTemplate({
			variables: {
				input: {
					name: name.value,
					body: emailBody,
				},
			},
		})
	}

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Nouveau template', url: '/nouveau-template' },
			])
		)
	}, [updateName])
	if (loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'templateCreateMsg',
			'✔️ La template a été crée avec succès ! '
		)
		return <Redirect to="/liste-templates" />
	}

	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					<TextField
						label="Nom"
						name="name"
						size="small"
						margin="normal"
						style={{ width: '300px' }}
						inputProps={{ maxLength: 20 }}
					/>
				</FormGroup>

				<FormGroup className="formGrp" row>
					{/* <TextareaAutosize
						aria-label="textarea"
						placeholder=""
						name="body"
						minRows={12}
						style={{
							width: '100%',
							maxWidth: '800px',
							padding: '10px',
							borderRadius: '5px',
							border: '1px solid #ccc',
							fontFamily: 'inherit',
							fontSize: '16px',
						}}
					/> */}
					<ReactQuill
						value={emailBody}
						onChange={setEmailBody}
						modules={{
							toolbar: [
								[{ header: [1, 2, false] }],
								['bold', 'italic', 'underline'],
								[{ color: [] }, { background: [] }],
								[{ align: [] }],
								[{ list: 'ordered' }, { list: 'bullet' }],
								['link', 'image'],
								['clean'],
							],
						}}
						style={{
							height: '400px',
							width: '900px',
							maxWidth: '900px',
							marginBottom: '30px',
						}}
					/>
				</FormGroup>

				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
			</Box>
		</>
	)
}

export default CreateTemplate
