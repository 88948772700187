import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { getFileIcon } from './fileUtils'

export const AttachmentList = ({ attachments, onRemove, horizontal }) => (
	<div style={{}}>
		<ul
			style={
				horizontal
					? {
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
							overflowX: 'auto', // Horizontal scroll for overflow
							width: '400px', // Fixed width for the attachments container
							padding: '5px 0',
					  }
					: {
							listStyle: 'none',
							padding: 0,
					  }
			}
		>
			{attachments?.map((attachment, index) => (
				<li
					key={index}
					style={
						horizontal
							? {
									position: 'relative',
									display: 'flex',
									alignItems: 'center',
									gap: '5px',
							  }
							: {
									display: 'flex',
									alignItems: 'center',
									marginBottom: '10px',
							  }
					}
				>
					{onRemove && (
						<CloseIcon
							style={{
								position: 'absolute',
								top: '-5px',
								right: '-5px',
								cursor: 'pointer',
								backgroundColor: '#fff',
								borderRadius: '50%',
								color: '#d32f2f',
								fontSize: '16px',
								boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
							}}
							onClick={() => onRemove(index)}
						/>
					)}
					{getFileIcon(attachment.contentType)}

					{horizontal ? (
						<span
							style={{
								fontSize: '0.9rem',
								color: '#555',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								maxWidth: '100px',
							}}
							title={attachment.name}
						>
							{attachment.name}
						</span>
					) : (
						<a
							href={`data:${attachment.contentType};base64,${attachment.contentBytes}`}
							download={attachment.name || 'attachment'}
							style={{
								textDecoration: 'none',
								color: '#1976d2',
								fontWeight: 'bold',
							}}
						>
							{attachment.name}
						</a>
					)}
				</li>
			))}
		</ul>
	</div>
)
