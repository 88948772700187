// fileUtils.js
import React from 'react'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile' // Generic file
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf' // PDF
import ImageIcon from '@mui/icons-material/Image' // Image
import ArchiveIcon from '@mui/icons-material/Archive' // ZIP, RAR
import AudiotrackIcon from '@mui/icons-material/Audiotrack' // Audio
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary' // Video

export const getFileIcon = (contentType) => {
	if (contentType.startsWith('image/')) {
		return <ImageIcon style={{ color: '#1976d2', marginRight: '8px' }} />
	} else if (contentType === 'application/pdf') {
		return <PictureAsPdfIcon style={{ color: '#d32f2f', marginRight: '8px' }} />
	} else if (
		contentType === 'application/zip' ||
		contentType === 'application/x-rar-compressed' ||
		contentType === 'application/x-zip-compressed'
	) {
		return <ArchiveIcon style={{ color: '#ff9800', marginRight: '8px' }} />
	} else if (contentType.startsWith('audio/')) {
		return <AudiotrackIcon style={{ color: '#388e3c', marginRight: '8px' }} />
	} else if (contentType.startsWith('video/')) {
		return <VideoLibraryIcon style={{ color: '#7b1fa2', marginRight: '8px' }} />
	} else {
		return (
			<InsertDriveFileIcon style={{ color: '#607d8b', marginRight: '8px' }} />
		)
	}
}
