import React, { useEffect, useContext } from 'react'
// import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import ListIcon from '@mui/icons-material/List'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery, useMutation } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import ViewListIcon from '@mui/icons-material/ViewList'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import WarningIcon from '@mui/icons-material/Warning'
import * as moment from 'moment'

import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'

import FilterListOffIcon from '@mui/icons-material/FilterListOff'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

import EditIcon from '@material-ui/icons/BorderColor'
import { useCurrentUser } from '../../../currentUserContext'

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: '800px',
	},
})

const ALL_TASKS = loader('./graphql/allTasks.graphql')
const DELETE_TASK = loader('./graphql/deleteTask.graphql')

export default function TaskList() {
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des tâches')
	const currentUser = useCurrentUser()
	const isAdmin =
		currentUser && currentUser.roles.find(({ role }) => role === 'admin')
	const [sortModel, setSortModel] = React.useState([
		{
			field: 'updatedAt',
			sort: 'desc',
		},
	])

	const [filterModel, setFilterModel] = React.useState()

	const {
		data: { allTasks = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_TASKS)

	const [
		deleteTask,
		{
			data: deleteTaskData,
			loading: deleteTaskloading,
			error: deleteTaskError,
		},
	] = useMutation(DELETE_TASK)

	const handleFilter = () => {
		setFilterModel({
			items: [
				{
					columnField: 'assignedTo',
					operatorValue: 'equals',
					value: currentUser.userName,
				},
			],
		})
	}

	const handleRemoveTask = (id) => {
		return deleteTask({
			variables: {
				input: id,
			},
		})
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					onClick={handleFilter}
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root  css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<ListIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Mes tâches
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'assignedTo.color',
			filterable: false,
			headerName: '',
			maxWidth: 20,
			sortable: false,
			valueGetter: ({ value }) => value?.color,
			renderCell: (params) => {
				const { row } = params
				return (
					<div
						style={{
							width: '100px',
							height: '100%',
							backgroundColor: row?.assignedTo?.color,
						}}
					></div>
				)
			},
		},
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			minWidth: 120,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)
				return (
					<>
						{currentUser.userName === thisRow.assignedTo || isAdmin ? (
							<Link
								to={`/modifier-tache/${thisRow.id}`}
								style={{ margin: '0 auto' }}
								className="listEditBtn"
							>
								<EditIcon className="editBtnIcon" /> Modifier
							</Link>
						) : (
							<Link
								to={`/modifier-tache/${thisRow.id}`}
								style={{ margin: '0 auto' }}
								className="listEditBtn"
							>
								<RemoveRedEyeIcon className="editBtnIcon" /> Afficher
							</Link>
						)}
					</>
				)
			},
		},
		{
			field: 'remove',
			headerName: 'Supprimer',
			hide: !isAdmin,
			filterable: false,
			sortable: false,
			minWidth: 120,
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)
				return (
					<>
						<Button
							onClick={() => handleRemoveTask({ id: thisRow.id })}
							style={{ fontSize: '11px' }}
							title={thisRow.id}
							variant="contained"
							color="error"
						>
							<DeleteIcon className="editBtnIcon" /> Supprimer
						</Button>
					</>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 120,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'number',
			headerName: 'Number',
			minWidth: 120,
			flex: 1,
		},
		{
			field: 'client',
			headerName: 'Société',
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<strong style={{ textTransform: 'capitalize', marginLeft: '20px' }}>
						{row.client.company}
					</strong>
				)
			},
			valueGetter: (params) => params.row?.client?.company?.toString(),
		},
		{
			field: 'client.adminstrator',
			headerName: 'Administrateur',
			minWidth: 150,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<span style={{ textTransform: 'capitalize', marginLeft: '20px' }}>
						{row.client.adminstrator}
					</span>
				)
			},
			valueGetter: (params) => params.row?.client?.adminstrator?.toString(),
		},
		{
			field: 'servicesInTask',
			headerName: 'Services',
			minWidth: 50,
			align: 'center',
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<NoMaxWidthTooltip
							placement="right-start"
							style={{ cursor: 'help' }}
							title={
								<TableContainer component={Paper}>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Service</TableCell>
												<TableCell>Quantité</TableCell>
												<TableCell>Remarque</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{row.servicesInTask.map((elm, i) => {
												return (
													<TableRow
														key={i}
														sx={{
															'&:last-child td, &:last-child th': { border: 0 },
														}}
													>
														<TableCell component="th" scope="row">
															{elm.service.name}
														</TableCell>
														<TableCell align="right">{elm.quantity}</TableCell>
														<TableCell align="right">
															{elm.remark.length === 0
																? ''
																: elm.remark.length < 80
																? elm.remark
																: elm.remark.substr(0, 80) + '\u2026'}
														</TableCell>
													</TableRow>
												)
											})}
										</TableBody>
									</Table>
								</TableContainer>
							}
						>
							<Box sx={{ position: 'relative', display: 'inline-flex' }}>
								<Badge badgeContent={row.servicesInTask.length} color="primary">
									<ViewListIcon />
								</Badge>
							</Box>
						</NoMaxWidthTooltip>
					</>
				)
			},
		},
		{
			field: 'assignedTo',
			headerName: 'Assigné à',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => value?.userName,
			renderCell: (params) => {
				const { row } = params
				return (
					<span style={{ textTransform: 'capitalize' }}>
						{row.assignedTo?.userName}
					</span>
				)
			},
		},
		{
			field: 'Status',
			headerName: 'Status',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Stack
							direction="row"
							spacing={1}
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{row.status === 'inProgress' ? (
								<Chip label="En cours" color="primary" />
							) : row.status === 'pending' ? (
								<Chip label="En attent" color="secondary" />
							) : row.status === 'pending' ? (
								<Chip label="En attent" color="secondary" />
							) : (
								<Chip label="Terminer" color="success" />
							)}
						</Stack>
					</>
				)
			},
			valueGetter: (params) =>
				params.row.status === 'inProgress'
					? 'En cours'
					: params.row.status === 'pending'
					? 'En attent'
					: 'Terminer',
		},
		{
			field: 'createdBy',
			headerName: 'Créé par',
			minWidth: 90,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Stack
							direction="row"
							spacing={1}
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Tooltip title={row?.createdBy?.userName.toUpperCase()}>
								<Avatar sx={{ bgcolor: row?.createdBy?.color }}>
									{row?.createdBy?.userName !== ''
										? row?.createdBy?.userName?.substring(0, 2).toUpperCase()
										: 'AA'}
								</Avatar>
							</Tooltip>
						</Stack>
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},

		{
			field: 'createdAt',
			headerName: 'Date de création',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
		{
			field: 'updatedBy',
			headerName: 'Modifié par',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						{row.updatedBy && (
							<Stack
								direction="row"
								spacing={1}
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Tooltip title={row?.updatedBy?.userName?.toUpperCase()}>
									<Avatar sx={{ bgcolor: row?.updatedBy?.color }}>
										{row.updatedBy !== ''
											? row.updatedBy?.userName?.substring(0, 2).toUpperCase()
											: 'AA'}
									</Avatar>
								</Tooltip>
							</Stack>
						)}
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},
		{
			field: 'updatedAt',
			headerName: 'Dérniére modification',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
	]

	if (localStorage.getItem('taskCreateMsg') !== '') {
		toast.success(localStorage.getItem('taskCreateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('taskCreateMsg', '')
			localStorage.setItem('taskUpdateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [deleteTaskData])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{ level: '1', title: 'Liste des tâches', url: '/liste-taches' },
			])
		)
	}, [updateName])

	useEffect(() => {
		if (deleteTaskError) {
			toast.warn(
				'⚠️ Cette tâche est déjà associée à une facture existante et ne peut pas être supprimée.',
				{ theme: 'colored', icon: <WarningIcon /> }
			)
		}
	}, [deleteTaskError])

	if (loading) return <Spinner />

	return (
		<>
			{/* {deleteTaskError && alert(deleteTaskError?.message)} */}

			<ToastContainer position="bottom-center" transition={Slide} />

			{allTasks && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allTasks}
					autoHeight={true}
					columns={columns}
					sortModel={sortModel}
					onSortModelChange={(model) => setSortModel(model)}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
					filterModel={filterModel}
					onFilterModelChange={(newFilterModel) =>
						setFilterModel(newFilterModel)
					}
				/>
			)}
		</>
	)
}
