import React, { useEffect, useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import TextareaAutosize from '@mui/material/TextareaAutosize' // Import Material-UI TextareaAutosize
import SaveIcon from '@material-ui/icons/Save'
import { Button } from '@material-ui/core'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Spinner from '../../../UI/Spinner'
import SystemInfo from '../../../UI/SystemInfo'

import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'

import '../../../../asset/style.css'

const TEMPLATE = loader('./graphql/template.graphql')
const UPDATE_TEMPLATE = loader('./graphql/updateTemplate.graphql')

const EditTemplate = () => {
	const [emailBody, setEmailBody] = useState('')
	const { updateName } = useContext(Context)
	const params = useParams()

	const { data: { template = null } = {}, loading: templateLoading } = useQuery(
		TEMPLATE,
		{
			variables: { id: params.id },
		}
	)

	const [updateTemplate, { data, error, loading }] =
		useMutation(UPDATE_TEMPLATE)

	const handleSubmit = (e) => {
		e.preventDefault()
		const { name, body } = e.target
		return updateTemplate({
			variables: {
				input: {
					id: params.id,
					name: name.value,
					body: body.value,
				},
			},
		})
	}

	useEffect(() => {
		if (template) {
			setEmailBody(template.body) // Populate ReactQuill with the template's body
		}
	}, [template])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Modifier Template',
					url: `/modifier-template/${template && template?.id}`,
				},
				{
					level: '2',
					title: `${template && template?.name}`,
					url: `/modifier-template/${template && template?.id}`,
				},
			])
		)
	}, [updateName, template])

	useDocumentTitle(
		template ? 'Modifer le template ' + template?.title : 'Modifer template '
	)

	if (templateLoading || loading) return <Spinner />

	if (data) {
		localStorage.setItem(
			'templateUpdateMsg',
			'✔️ La template a été modifié avec succès ! '
		)
		return <Redirect to="/liste-templates" />
	}
	return (
		<>
			<Box
				className="mainZone"
				component="form"
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit}
			>
				<FormGroup className="formGrp" row>
					<TextField
						label="Template"
						name="name"
						size="small"
						margin="normal"
						style={{ width: '300px' }}
						inputProps={{ maxLength: 20 }}
						defaultValue={template && template.name}
					/>
				</FormGroup>
				<FormGroup className="formGrp" row>
					{/* <TextareaAutosize
						aria-label="textarea"
						placeholder=""
						name="body"
						minRows={12}
						defaultValue={template && template.body}
						style={{
							width: '100%',
							maxWidth: '800px',
							padding: '10px',
							borderRadius: '5px',
							border: '1px solid #ccc',
							fontFamily: 'inherit',
							fontSize: '16px',
						}}
					/> */}
					<ReactQuill
						value={emailBody}
						onChange={setEmailBody}
						modules={{
							toolbar: [
								[{ header: [1, 2, false] }],
								['bold', 'italic', 'underline'],
								[{ color: [] }, { background: [] }],
								[{ align: [] }],
								[{ list: 'ordered' }, { list: 'bullet' }],
								['link', 'image'],
								['clean'],
							],
						}}
						style={{
							height: '400px',
							width: '900px',
							maxWidth: '900px',
							marginBottom: '30px',
						}}
					/>
				</FormGroup>
				<FormGroup className="formGrp formSubmitBtn" row>
					<Button
						className="submitBtn"
						type="submit"
						variant="contained"
						color="primary"
					>
						{' '}
						<SaveIcon />
						&nbsp;Enregistrer{' '}
					</Button>
				</FormGroup>
				<FormGroup className="formGrp" row>
					<SystemInfo
						createdBy={template?.createdBy}
						createdAt={template?.createdAt}
						updatedBy={template?.updatedBy}
						updatedAt={template?.updatedAt}
					/>
				</FormGroup>
			</Box>
		</>
	)
}

export default EditTemplate
