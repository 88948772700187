import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useQuery, useMutation } from '@apollo/client'
import Spinner from '../../../UI/Spinner'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport,
	GridToolbarDensitySelector,
	frFR,
} from '@mui/x-data-grid'
import * as moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import Avatar from '@mui/material/Avatar'
import AddIcon from '@mui/icons-material/Add'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { ToastContainer, toast, Slide } from 'react-toastify'
import '../../../UI/toastify/ReactToastify.min.css'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import { Context } from '../../../breadcrumsContext'
import { useDocumentTitle } from '../../../documentTitle'
import '../../../../asset/style.css'
import EditIcon from '@material-ui/icons/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'

const ALL_TEMPLATES = loader('./graphql/allTemplates.graphql')
const REMOVE_TEMPLATE = loader('./graphql/removeTemplate.graphql')

export default function emailTemplate() {
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [templateIdToDelete, setTemplateIdToDelete] = useState(null)
	const { updateName } = useContext(Context)
	useDocumentTitle('Liste des templates')

	const {
		data: { allTemplates = null } = {},
		loading,
		refetch,
	} = useQuery(ALL_TEMPLATES)
	const [
		removeTemplate,
		{
			data: removeTemplateData,
			error: removeTemplateError,
			loading: removeTemplateLoading,
		},
	] = useMutation(REMOVE_TEMPLATE)

	const openDialog = () => {
		setIsDialogOpen(true)
	}

	const closeDialog = () => {
		setIsDialogOpen(false)
	}

	const handleRemoveTemplate = ({ id }) => {
		setTemplateIdToDelete(id)
		openDialog()
	}

	const handleRemoveTemplateConfirmation = (templateId) => {
		removeTemplate({
			variables: {
				input: {
					id: templateId,
				},
			},
			update: (cache) => {
				// Manually update the cache to remove the deleted template
				const cachedData = cache.readQuery({ query: ALL_TEMPLATES })
				if (cachedData) {
					const updatedData = {
						allTemplates: cachedData.allTemplates.filter(
							(template) => template.id !== templateId
						),
					}
					cache.writeQuery({ query: ALL_TEMPLATES, data: updatedData })
				}
			},
		})
			.then(() => {
				toast.success('La template a été supprimée avec succès.')
			})
			.catch((error) => {
				toast.error(
					"Une erreur s'est produite lors de la suppression de la template."
				)
			})
	}

	function CustomToolbar() {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<GridToolbarDensitySelector />
				<GridToolbarExport />
				<Button
					component={Link}
					to="/nouveau-template/"
					className="MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButtonBase-root css-1knaqv7-MuiButtonBase-root-MuiButton-root"
				>
					<AddIcon className="MuiButton-startIcon MuiButton-iconSizeSmall css-y6rp3m-MuiButton-startIcon" />{' '}
					Nouveau template
				</Button>
			</GridToolbarContainer>
		)
	}

	const columns = [
		{
			field: 'actions',
			headerName: 'Action',
			filterable: false,
			sortable: false,
			minWidth: 300,
			headerClassName: 'listHeader',
			renderCell: (params) => {
				const api = params.api
				const thisRow = {}

				api
					.getAllColumns()
					.filter((c) => c.field !== '__check__' && !!c)
					.forEach(
						(c) => (thisRow[c.field] = params.getValue(params.id, c.field))
					)

				return (
					<>
						<Link
							to={`/modifier-template/${thisRow.id}`}
							style={{ margin: '0 auto' }}
							className="listEditBtn"
						>
							<EditIcon className="editBtnIcon" /> Modifier
						</Link>
						<Button
							onClick={() => handleRemoveTemplate({ id: thisRow.id })}
							style={{ margin: '0 auto' }}
							className="listremoveBtn"
							title={thisRow.id}
							variant="contained"
							color="error"
						>
							<DeleteIcon className="editBtnIcon" /> Supprimer
						</Button>
					</>
				)
			},
		},
		{
			field: 'id',
			headerName: 'ID',
			minWidth: 150,
			hide: true,
			flex: 1,
			filterable: false,
			sortable: false,
		},
		{
			field: 'name',
			headerName: 'Template',
			minWidth: 250,
			flex: 1,
		},
		{
			field: 'createdBy',
			headerName: 'Créé par',
			minWidth: 90,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						<Stack
							direction="row"
							spacing={1}
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Tooltip title={row?.createdBy?.userName?.toUpperCase()}>
								<Avatar sx={{ bgcolor: row?.createdBy?.color }}>
									{row?.createdBy?.userName !== ''
										? row?.createdBy?.userName?.substring(0, 2).toUpperCase()
										: 'AA'}
								</Avatar>
							</Tooltip>
						</Stack>
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},

		{
			field: 'createdAt',
			headerName: 'Date de création',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
		{
			field: 'updatedBy',
			headerName: 'Modifié par',
			minWidth: 100,
			flex: 1,
			renderCell: (params) => {
				const { row } = params
				return (
					<>
						{row.updatedBy && (
							<Stack
								direction="row"
								spacing={1}
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Tooltip title={row?.updatedBy?.userName?.toUpperCase()}>
									<Avatar sx={{ bgcolor: row?.updatedBy?.color }}>
										{row.updatedBy !== ''
											? row.updatedBy?.userName?.substring(0, 2).toUpperCase()
											: 'AA'}
									</Avatar>
								</Tooltip>
							</Stack>
						)}
					</>
				)
			},
			valueGetter: ({ value }) => value?.userName,
		},
		{
			field: 'updatedAt',
			headerName: 'Dérniére modification',
			minWidth: 150,
			flex: 1,
			valueGetter: ({ value }) => {
				return moment(value, 'x').format('DD/MM/YY HH:mm')
			},
		},
	]

	if (localStorage.getItem('templateCreateMsg') !== '') {
		toast.success(localStorage.getItem('templateCreateMsg'))
	}

	if (localStorage.getItem('templateUpdateMsg') !== '') {
		toast.success(localStorage.getItem('templateUpdateMsg'))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			localStorage.setItem('templateCreateMsg', '')
			localStorage.setItem('templateUpdateMsg', '')
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	useEffect(() => {
		refetch()
	}, [allTemplates])

	useEffect(() => {
		updateName(
			JSON.stringify([
				{
					level: '1',
					title: 'Liste des templates',
					url: '/liste-templates',
				},
			])
		)
	}, [updateName])

	if (loading || removeTemplateLoading) return <Spinner />
	console.log('allTemplates', allTemplates)
	return (
		<>
			<ToastContainer position="bottom-center" transition={Slide} />
			{allTemplates && (
				<DataGrid
					disableSelectionOnClick={true}
					localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					rows={allTemplates}
					autoHeight={true}
					columns={columns}
					components={{
						Toolbar: CustomToolbar,
						NoResultsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>Filter returns no result</p>
								</Stack>
							)
						},
						NoRowsOverlay: () => {
							return (
								<Stack
									style={{ marginTop: '600px !important' }}
									alignItems="center"
									justifyContent="center"
								>
									<FilterListOffIcon />
									<p>No Rows</p>
								</Stack>
							)
						},
					}}
				/>
			)}

			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				aria-labelledby="confirmation-dialog-title"
			>
				<DialogTitle id="confirmation-dialog-title">
					Confirmer la suppression
				</DialogTitle>
				<DialogContent>
					Êtes-vous sûr de vouloir supprimer cette template?
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog} color="primary">
						Annuler
					</Button>
					<Button
						onClick={() => {
							handleRemoveTemplateConfirmation(templateIdToDelete)
							closeDialog()
						}}
						color="primary"
					>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
